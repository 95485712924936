import {
	getData
} from 'requestModel'
import api from '../api/apiNews'

const getNewsList = (list, type) => {
	let currNewsList = []

	if (!type) {
		return list
	} else {
		list.length && list.forEach((v, i) => {
			if (v.type == type) currNewsList.push(v)
		})

		return currNewsList
	}
}

export const state = () => ({
	list: [],
	total: 0
})

export const actions = {
	async getNewsList ({
		commit
	}, params = {}) {
		let ret = await getData({
			api,
			dataset: ['getNewsList'],
			onError () {

			},
			//传给api的参数
			params: []
		})

		if (ret && ret.list) {
			commit('setData', getNewsList(ret.list, params.type))
		} else {
			ret = []
		}

		return ret
	}
}

export const mutations = {
	setData (state, value) {
		state.list = value
		state.total = value.length || 0
	}
}
